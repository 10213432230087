import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import '../css/sidebar.css'
import '../img/tkgt.png'


class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
   /* if (!isAuthenticated) {
      return (
        <div>
          <Route exact path="/" render={() => <Redirect to="/tests" />} />
        </div>
      );
    }*/
    return (
    
      
        <div className="wrapper">
        <nav id="sidebar">
            <div className="sidebar-header">
                <h3>Globe Tracker</h3>
                    <img className="logoimg" src={require('../img/globetracker_logo.png')} />
            </div>

            <ul className="list-unstyled components">
            
                <li className="active">
                    <ul id="homeSubmenu">
                    <a href="/"> Home</a>
                    <a href="/allinstallations"> Installations</a>
                    <a href="/admin"> Admin</a>
                    </ul>
                </li>
               
            </ul>
        </nav>
        {this.props.children}

    </div>

      );
  }
}
const mapStateToProps = state => {
    return {
      //isFetching: state.auth.isFetching,
      //isAuthenticated: state.auth.isAuthenticated,
      //message: state.auth.message
    };
  };

export default connect(mapStateToProps)(Sidebar);