import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
// import { loginUser } from "../api/thunk/auth";
import 'bootstrap/dist/css/bootstrap.css';
import '../css/login.css'

//const { check, validationResult } = require('express-validator/check');
//const { sanitize } = require('express-validator/filter');

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      isFetching: false,
      isAuthenticated: false,
      message: null
    };
  }

  handleInputChange = e => {
    const { name, value } = e.target;

    if (name) {
      this.setState({ [name]: value });
    }
  };

  handleSubmit = async e => {
    e.preventDefault();
    console.log('login');
    const { dispatch } = this.props;
    const { username, password } = this.state;
    // dispatch(loginUser(username, password));
  };

  render() {
    const { username, password } = this.state;
    const { isFetching, isAuthenticated } = this.props;
    console.log(this.state.username);
    if (isAuthenticated) {
      return (
        <div>
          <Route exact path="/" render={() => <Redirect to="/" />} />
        </div>
      );
    }
    return (
    <div id="loginModal">
    <div className="modal-dialog">
    <div className="modal-content">
    <div className="modal-header">
      <button type="button" className="close">×</button>
        <h1 className="text-center">Login</h1>
      </div>
      <div className="modal-body">
        <form className="form col-md-12 center-block">
            <div className="form-group">
              <input type="text" className="form-control input-lg" placeholder="Email"/>
            </div>
            <div className="form-group">
              <input type="password" className="form-control input-lg" placeholder="Password"/>
            </div>
            <div className="form-group">
              <button className="btn btn-primary btn-lg btn-block" onClick={this.handleSubmit}>Sign In</button>
              <span className="pull-right"><a href="#">Register</a></span><span><a href="#">Need help?</a></span>
            </div>
          </form>
      </div>
      <div className="modal-footer">
          <div className="col-md-1accordion2">
          <button className="btn">Cancel</button>
    	  </div>
      </div>
  </div>
  </div>
</div>

    );
  }
}
const mapStateToProps = state => {
  return {
    //isFetching: state.auth.isFetching,
    //isAuthenticated: state.auth.isAuthenticated,
    //message: state.auth.message
  };
};
export default connect(mapStateToProps)(LoginForm);

/*</div> <div className="login">
        <p className="login__login"></p>
        <div className="login__input">
          <input
            name="username"
            id="inputUsername"
            type="text"
            value={this.state.username}
            onChange={this.handleInputChange}
            placeholder="Username"
            required
            autoFocus
            className="form-control"
          />
        </div>
        <div className="login__input">
          <input
            name="password"
            type="password"
            id="inputPassword"
            className="form-control"
            placeholder="Password"
            value={this.state.password}
            onChange={this.handleInputChange}
            required
          />
        </div>
        <button className="login__button" onClick={this.handleSubmit}>
          Login
        </button>
      </div>*/
