import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
//import Header from '../../components/Header'
//import Footer from '../../components/Footer'
import Sidebar from '../../components/Sidebar'
import Installations from '../../components/Installations'

class AllInstallations extends Component {
  render() {
    const token = window.localStorage.getItem('user');
    if (token != null) {
    	return (
	      <div id="page-top">
				<Sidebar>
					<Installations/>
					</Sidebar>

	      </div>
	

	    );
		}
		else {
    	return (
	      <div id="page-top">
				<Sidebar>
				<Installations/>
				</Sidebar>
	      </div>
	

	    );
    }
  }
}

export default AllInstallations;