import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
//import Header from '../../components/Header'
//import Footer from '../../components/Footer'
import Sidebar from '../../components/Sidebar'
import ChangeDefaults from '../../components/ChangeDefaults'

class Admin extends Component {
  render() {
    const token = window.localStorage.getItem('user');
    if (token != null) {
    	return (
	      <div id="page-top">
				<Sidebar>
					<ChangeDefaults/>
					</Sidebar>
	      </div>
	

	    );
		}
		else {
    	return (
	      <div id="page-top">
				<Sidebar>
					<ChangeDefaults/>
					</Sidebar>
	      </div>
	

	    );
    }
  }
}

export default Admin;