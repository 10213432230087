

const baseurl = "https://devsol.globetracker.com/api/v0/gt-install";
const gtClient="gt-install-mobile"
const gtSecret="gt-install-dev"
export async function get(endpoint) {

  const token = "Z3QtaW5zdGFsbC1hcHA6MDJmN2FkNjY2MTUz";

  const url = `${baseurl}${endpoint}`;

  const options = {
    headers: {
      "content-type": "application/json",

  }
  };

  if (token) {
    options.headers["Authorization"] = `Basic ${token}`;
  }

  const response = await fetch(url, options);
  const result = await response.json();

  return { result, status: response.status };
}

export async function post(endpoint, data) {
  const url = `${baseurl}${endpoint}`;
  const token = "Z3QtaW5zdGFsbC1hcHA6MDJmN2FkNjY2MTUz";
  const options = {
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json"
    },
    method: "POST"
  };
  if (token) {
    options.headers["Authorization"] = `Basic ${token}`;
  }
   
  const response = await fetch(url, options);
  const result = await response.json();

  return { result, status: response.status };
}


export async function patch(endpoint, data) {
  const url = `${baseurl}${endpoint}`;
  const token = window.localStorage.getItem("token");

  const options = {
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json"
    },
    method: "PATCH"
  };

  if (token) {
    options.headers["Authorization"] = `Bearer ${token}`;
  }

  const response = await fetch(url, options);
  const result = await response.json();

  return { result, status: response.status };
}


export default {
  get,
  post,
  patch,
};