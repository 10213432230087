import React, { Component } from "react";
import QrReader from "react-qr-reader";

import "../css/newinstallform.css";
import { post } from "../api/api";

class InstallationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      model: "",
      assetType: "",
      gtCommUnitSerial: "",
      result: "enginn",
      containerSize: "",
      fuelProbe: "",
      fuelProbeProfile: "",
      manufacturer: "",
      id: "",
      owner_id: "",
      tags: [],
      type: "",
      asset_id: "",
      custom_fields: {},
      id: "",
      owner_id: "",

      sensors: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.setState({ manufacturer: localStorage.getItem("manufacturer") });
    this.setState({ assetType: localStorage.getItem("assetType") });
    this.setState({ fuelProbe: localStorage.getItem("fuelProbe") });
    this.setState({
      fuelProbeProfile: localStorage.getItem("fuelProbeProfile")
    });
  }
  handleScan = data => {
    if (data) {
      var i = data.search("MODEL:");
      var j = data.search("Build:");
      var mdl = data.substring(i + 6, j);
      i = data.search("SN:");
      var unit = data.substring(0, i);
      this.setState({
        model: mdl,
        gtCommUnitSerial: unit,
        result: data
      });
    }
  };
  handleError = err => {
    console.error(err);
  };
  handleInputChange = e => {
    const { name, value } = e.target;

    if (name) {
      this.setState({ [name]: value });
    }
  };
  async handleSubmit() {
    const {
      fuelProbe,
      fuelProbeProfile,
      manufacturer,
      assetType,
      asset_id
    } = this.state;

    var owner_id = "GT00";
    var tags = ["_GENSET,_TK,_OMNICOMM,_EMERSON"];
    var type = "Genset";
    var id = "999112";
    var containerSize = "N/A";
    let asset = {
      custom_fields: {
        containerSize: containerSize,
        fuelProbe: fuelProbe,
        fuelTankProfile: fuelProbeProfile,
        manufacturer: manufacturer
      },
      id: asset_id,
      owner_id: owner_id,
      tags: tags,
      type: assetType
    };

    let boxes = [
      {
        asset_id: asset_id,
        custom_fields: {
          placement: null
        },
        id: id,
        owner_id: owner_id
      }
    ];

    let sensors = [];
    var data = {
      asset,
      boxes,
      sensors
    };

    try {
      let resp = await post("/installs", data);
      localStorage.setItem("installs", JSON.stringify(data));

      this.setState({ isFetching: false });
      try {
        var storedIds = JSON.parse(localStorage.getItem("ids"));
      } catch (e) {
        var storedIds = [];
      }
      if (resp.status == 200) {
        storedIds.push({ id: resp.result.id, name: asset_id });
      } else {
        try {
          var prevdata = [];
          var prevdata = JSON.parse(localStorage.getItem("uninstalled"));
        } catch (e) {
          var prevdata = [];
        }
        data = {
          asset,
          boxes,
          sensors,
          resp
        };
        prevdata.push(data);
        localStorage.setItem("uninstalled", JSON.stringify(prevdata));
        localStorage.setItem("ids", JSON.stringify(storedIds));
      }
      localStorage.setItem("ids", JSON.stringify(storedIds));
    } catch (e) {
      console.error("Error fetching data", e);
      localStorage.setItem("uninstalled", JSON.stringify(data));
      this.setState({ error: true, isFetching: false });
    }
  }

  render() {
    return (
      <div className="inputform">
        <h1>New Installation</h1>
        <div id="reader" width="1px"></div>
        <div className="input-group mb-3">
          <QrReader
            delay={300}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{ width: "0%" }}
          />
          <label>Model</label>

          <div className="input-group mb-3">
            <input
              name="model"
              type="text"
              value={this.state.model}
              onChange={this.handleInputChange}
              placeholder="Model"
              aria-label="Model"
              className="form-control"
            />
          </div>
          <label>Manufacturer</label>

          <div className="input-group mb-3">
            <select
              name="manufacturer"
              type="text"
              onChange={this.handleInputChange}
              placeholder="Manufacturer"
              aria-label="Manufacturer"
              className="form-control"
            >
              <option value="" selected="selected"></option>
              <option value={this.state.manufacturer}>
                {this.state.manufacturer}
              </option>
            </select>
          </div>
          <label>Asset Type</label>

          <div className="input-group mb-3">
            <select
              name="assetType"
              type="text"
              onChange={this.handleInputChange}
              placeholder="Asset Type"
              aria-label="Asset Type"
              className="form-control"
            >
              <option value="" selected="selected"></option>
              <option value={this.state.assetType}>
                {this.state.assetType}
              </option>
            </select>
          </div>
          <label>Asset ID</label>
          <div className="input-group mb-3">
            <input
              name="asset_id"
              type="text"
              value={this.state.asset_id}
              onChange={this.handleInputChange}
              placeholder="Asset ID"
              aria-label="Asset ID"
              className="form-control"
            />
          </div>
          <label>Fuel Probe &amp; Fuel Probe Profile</label>

          <div className="input-group mb-3">
            <select
              name="fuelProbe"
              type="text"
              onChange={this.handleInputChange}
              placeholder="Fuel Probe"
              aria-label="Fuel Probe"
              className="form-control"
            >
              <option value="" selected="selected"></option>
              <option value={this.state.fuelProbe}>
                {this.state.fuelProbe}
              </option>
            </select>
            <select
              name="fuelProbeProfile"
              type="text"
              onChange={this.handleInputChange}
              placeholder="Fuel Probe Profile"
              aria-label="Fuel Probe Profile"
              className="form-control"
            >
              <option value="" selected="selected"></option>
              <option value={this.state.fuelProbeProfile}>
                {this.state.fuelProbeProfile}
              </option>
            </select>
          </div>
          <label>GT Comm Unit Serial</label>

          <div className="input-group mb-3">
            <input
              name="gtCommUnitSerial"
              type="text"
              value={this.state.gtCommUnitSerial}
              onChange={this.handleInputChange}
              placeholder="GT Comm Unit Serial"
              aria-label="GT Comm Unit Serial"
              className="form-control"
            />
          </div>
        </div>
        <button className="unpButton" onClick={this.handleSubmit}>
          Unpair
        </button>
        <button className="addButton" onClick={this.handleSubmit}>
          Add
        </button>
      </div>
    );
  }
}

export default InstallationForm;
