import React, { Component } from "react";
import "../css/newinstallform.css";

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      manufacturer: "",
      assetType: "",
      fuelProbe: "",
      fuelProbeProfile: ""
    };
  }
  componentDidMount() {
    this.setState({ manufacturer: localStorage.getItem("manufacturer") });
    this.setState({ assetType: localStorage.getItem("assetType") });
    this.setState({ fuelProbe: localStorage.getItem("fuelProbe") });
    this.setState({
      fuelProbeProfile: localStorage.getItem("fuelProbeProfile")
    });
  }

  handleClick(e) {
    console.log(e.target.id);
  }
  handleInputChange = e => {
    const { name, value } = e.target;
    if (name) {
      this.setState({ [name]: value });
      localStorage.setItem(name, value);
    }
  };

  handleSubmit = async e => {
    e.preventDefault();
  };

  render() {
    return (
      <div className="inputform">
        <h1>Set defaults for installs</h1>
        <div className="input-group mb-3">
          <div className="input-group mb-3">
            <input
              name="manufacturer"
              type="text"
              value={this.state.manufacturer}
              onChange={this.handleInputChange}
              placeholder="Manufacturer"
              aria-label="Manufacturer"
              className="form-control"
            />
          </div>

          <div className="input-group mb-3">
            <input
              name="assetType"
              type="text"
              value={this.state.assetType}
              onChange={this.handleInputChange}
              placeholder="Asset Type"
              aria-label="Asset Type"
              className="form-control"
            />
          </div>

          <div className="input-group mb-3">
            <input
              name="fuelProbe"
              type="text"
              value={this.state.fuelProbe}
              onChange={this.handleInputChange}
              placeholder="Fuel Probe"
              aria-label="Fuel Probe"
              className="form-control"
            />
            <input
              name="fuelProbeProfile"
              type="text"
              value={this.state.fuelProbeProfile}
              onChange={this.handleInputChange}
              placeholder="Fuel Probe Profile"
              aria-label="Fuel Probe Profile"
              className="form-control"
            />
          </div>
          <button className="changeButton" onClick={this.handleSubmit}>
            Set Defaults
          </button>
        </div>
      </div>
    );
  }
}

export default Sidebar;
