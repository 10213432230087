import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
//import Header from '../../components/Header'
//import Footer from '../../components/Footer'
import LoginForm from '../../components/LoginForm'
import Sidebar from '../../components/Sidebar'
import InstallationForm from '../../components/InstallationForm'

class Login extends Component {
  render() {
    const token = window.localStorage.getItem('user');
    if (token != null) {
    	return (
	      <div id="page-top">
			<LoginForm/>
	      </div>
	

	    );
		}
		else {
    	return (
	      <div id="page-top">
			<LoginForm/>


	      </div>
	

	    );
    }
  }
}

export default Login;