import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Route, Switch, withRouter } from 'react-router-dom'

import Home from './routes/home';
import AllInstallations from './routes/installations';
import Admin from './routes/admin';
import NewInstall from './routes/newinstall'
import Login from './routes/login'


class App extends Component {
  render() {
    return (
      
      <div className="myApp">
        <Helmet defaultTitle="" titleTemplate="" />

        <div>
          <Switch location={this.props.location}>
            <Route path="/" exact component={Home} />
            <Route path='/login' component={Login} />
            <Route path="/allInstallations" exact component={AllInstallations} />
            <Route path="/admin" exact component={Admin} />
            <Route path="/newInstallation" exact component={NewInstall} />

          </Switch>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

export default withRouter(connect(mapStateToProps)(App));