import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../css/frontpagenav.css";

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleClick(e) {
    console.log(e.target.id);
  }

  render() {
    return (
      <div>
        <Link
          className="buttonFrontPage"
          to="/newInstallation"
          id="newinstallbutton"
        >
          New Installation
          <img src={require("../img/icon_new_install.png")} />
        </Link>
        <Link
          className="buttonFrontPage"
          to="/allInstallations"
          id="listinstallbutton"
        >
          List installations
          <img src={require("../img/icon_list_install.png")} />
        </Link>
      </div>
    );
  }
}

export default Sidebar;
