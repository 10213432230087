import { Component } from "react";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import '../css/frontpagenav.css'
// import { fetchInstalls } from '../api/thunk/installsthunk'
import { get, post } from '../api/api'
import '../css/allinstalls.css'

class Installations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      installs: [],
      isFetching: false,
      error: null,
      validations: [],
      uninstalled: []
    };
    this.handleUnpair = this.handleUnpair.bind(this);
    this.handleValidateAll = this.handleValidateAll.bind(this);

  }
  //[17774,17721,19465,19477,19490,20149,20175,20176]

  async componentDidMount() {
    try{
    var temp = JSON.parse(localStorage.getItem("ids"))
    }catch(e){
      var temp = []
    }
    let installs = []
    for (var id in temp) {
      try {
        let skr = await get(`/installs/status/${temp[id].id}`);
        if (skr.status == 200) {
          installs.push(skr)
        }

        this.setState({ installs: installs, isFetching: false });
      } catch (e) {
        console.error('Error fetching data', e);
        this.setState({ error: true, isFetching: false });
      }
    }
    try{
    var uninstalled = JSON.parse(localStorage.getItem("uninstalled"))
    }catch(e){
      var uninstalled=[]
    }
    for (var u in uninstalled) {
      uninstalled.push(uninstalled[u])
    }
    this.setState({ uninstalled: uninstalled })


  }

  async handleValidate(asset, boxes, sensors) {
    try {
      var data = {
        asset, boxes, sensors
      }
      let resp = await post('/installs', data);
      localStorage.setItem("installs", JSON.stringify(data));
      this.setState({ isFetching: false });
      try{
      var storedIds = JSON.parse(localStorage.getItem("ids"));
      }catch(e){
        var storedIds =[]
      }
      if (resp.status == 200) {
        storedIds.push({ id: resp.result.id, name: asset.asset_id })
      } else if(resp.status != 500) {
        try{
        var prevdata = JSON.parse(localStorage.getItem('uninstalled'))
        }catch(e){
          var prevdata=[]
        }
        data = {
          asset,
          boxes,
          sensors,
          resp
        }
        prevdata.push(data)
        localStorage.setItem("uninstalled", JSON.stringify(prevdata));
      }
      localStorage.setItem("ids", JSON.stringify(storedIds));
    } catch (e) {
      console.error('Error fetching data', e);
      localStorage.setItem("uninstalled", JSON.stringify(data));
      this.setState({ error: true, isFetching: false });
    }
  }

  handleUnpair(id) {
    const { validations } = this.state
    validations.find(x => x.id === id).displayUnpair = true;
  }
  async handleValidateAll(){
    const {uninstalled} = this.state
    for(var u in uninstalled){
      this.handleValidate(u.asset, u.boxes, u.sensors)
    }
  }

  render() {
    const { installs, validations, uninstalled } = this.state
    try{
    var temp = JSON.parse(localStorage.getItem("ids"))
    }catch(e){
      var temp = []
    }
    if (installs.length != 0) {
      for (var s in installs) {
        if (installs[s].result) {
          var obj = {
            id: null,
            asset_id: '',
            c: 'red',
            l: 'red',
            g: 'red',
            f: 'red',
            time: '',
            displayUnpair: false,
            temp: false,
            uninstalled: []
          }
          obj.id = installs[s].result.id;
          obj.asset_id = temp[s].name

          for (var d in installs[s].result.items) {
            if (installs[s].result.items.length != 0) {
              if (installs[s].result.items[d].label.includes('latest ocom')) {
                obj.g = 'green';
              }
              if (installs[s].result.items[d].label.includes('latest emerson')) {
                obj.c = 'green';
              }
              if (installs[s].result.items[d].type.includes('loc')) {
                obj.l = 'green';
              }
              if (installs[s].result.items[d].type.includes('fuel level')) {
                obj.f = 'green';
              }
              if (installs[s].result.items[d].key == "gtcm.message.latest.when") {
                obj.time = installs[s].result.items[d].value;
              }
            }
          }
        }
        if (obj.id != null) {
          validations.push(obj)
        }

      }

    }
    if (uninstalled.length != 0) {
      for (var u in uninstalled) {
        var obj = {
          id: null,
          asset_id: '',
          c: 'yellow',
          l: 'yellow',
          g: 'yellow',
          f: 'yellow',
          time: '',
          displayUnpair: false,
          temp: false,
          uninstalled: true,
          data: [],
          message: ''
        }
        obj.asset_id = uninstalled[u].asset.id
        obj.message = uninstalled[u].resp.result.message
        obj.temp = true
        obj.data.push(uninstalled[u].asset, uninstalled[u].boxes, uninstalled[u].sensors)
        validations.push(obj)

      }
    }
    if (validations.length != 0) {
      return (
        <div className="col-md-6 mb-4">
          <ul className="list-group">
            {validations.map((i, key) => (
              <li className="validation">
                <div className='val-rightside'>
                  <a className="btn-floating btn-fb btn-sm">{validations[key].asset_id}</a>
                  {validations[key].time ? <p className='timestamp'>{validations[key].time}</p> : <p></p>}
                  {validations[key].temp ? <div class="tempp">{validations[key].message}</div> : <div class="tempp"></div>}

                </div>
                <div className='validationImgs'>
                  {validations[key].c=='green' ? <div class="circlegreen">C</div> : validations[key].c=='yellow' ? <div class="circleyellow">C</div>: <div class="circlered">C</div>}
                  {validations[key].g=='green' ? <div class="circlegreen">G</div> : validations[key].g=='yellow' ? <div class="circleyellow">G</div>: <div class="circlered">G</div>}
                  {validations[key].f=='green' ? <div class="circlegreen">F</div> : validations[key].f=='yellow' ? <div class="circleyellow">F</div>: <div class="circlered">F</div>}
                  {validations[key].l=='green' ? <div class="circlegreen">L</div> : validations[key].l=='yellow' ? <div class="circleyellow">L</div>: <div class="circlered">L</div>}
                  <button className='unpairbtn' onClick={() => this.handleUnpair(validations[key].id)}>X</button>
                  {validations[key].displayUnpair ? <div><p>Unpair?</p></div> : <div><p></p></div>}


                  <button class="btn-green">
                    <img class="icon" onClick={() => this.handleValidate(validations[key].uninstalled.asset, validations[key].uninstalled.boxes, validations[key].uninstalled.sensors)} src="https://htmlacademy.ru/assets/icons/reload-6x-white.png" />
                  </button>
                </div>
              </li>

            ))}

          </ul>
          <button className='validateAllbtn'onClick={this.handleValidateAll}>Validate all</button>

        </div>);

    } else {
      return (
        <div>
<h2>No Installs yet</h2>
        </div>
      );
    }
  }
}

export default (Installations);
