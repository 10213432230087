import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Sidebar from '../../components/Sidebar'
import FrontPageNav from '../../components/FrontPageNav'

class Home extends Component {
  render() {
    const token = window.localStorage.getItem('user');
    if (token != null) {
    	return (
	      <div id="page-top">
				<Sidebar>
				<FrontPageNav/>
				</Sidebar>


	      </div>
	

	    );
		}
		else {
    	return (
	      <div id="page-top">
				<Sidebar>
				<FrontPageNav/>
				</Sidebar>

	      </div>
	

	    );
    }
  }
}

export default Home;